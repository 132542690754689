export default class Offcanvas {
    constructor() {
        this.offcanvasModals = document.querySelectorAll('[data-offcanvas-modal]');
        this.offcanvasTriggers = document.querySelectorAll('[data-offcanvas-trigger]');
        this.offcanvasClose = document.querySelectorAll('.js-offcanvas-close');
        this.overlay = document.querySelector('.js-overlay');

        if (this.offcanvasModals.length
            && this.offcanvasTriggers.length
            && this.offcanvasClose.length
            && this.overlay) {

            this.init();
        }
    }

    init() {
        this.offcanvasTriggers.forEach((trigger) => {
            trigger.addEventListener('click', () => {
               const dataTrigger = trigger.dataset.offcanvasTrigger;

               this.openModal(dataTrigger);
            });
        });

        this.offcanvasClose.forEach((trigger) => {
            trigger.addEventListener('click', () => {
                this.closeModals();
            });
        });

        this.overlay.addEventListener('click', () => {
            this.closeModals();
        });

        document.addEventListener('keyup', (event) => {
            if (event.defaultPrevented) {
                return;
            }

            // If the property have an undefined value, we'll look for keyCode.
            const key = event.key || event.keyCode;

            if (key === 'Escape' || key === 'Esc' || key === 27) {
                this.closeModals();
            }
        });
    }

    openModal(dataTrigger) {
        this.offcanvasModals.forEach((modal) => {
            if (modal.dataset.offcanvasModal === dataTrigger) {
                this.overlay.classList.add('active');
                modal.classList.add('displayed');
                document.body.classList.add('no-overflow');
            }
        });
    }

    closeModals() {
        this.overlay.classList.remove('active');
        document.body.classList.remove('no-overflow');

        this.offcanvasModals.forEach((modal) => {
            modal.classList.remove('displayed');
        })
    }
}
