export default class MenuResponsive {
    constructor() {
        this.responsiveMenu = document.querySelector('.js-responsive-menu');

        if (this.responsiveMenu) {
            this.init();
        }
    }

    init() {
        this.responsiveMenu.addEventListener('click', (e) => {
            if (e.target.classList.contains('js-menu-chevron') || e.target.closest('.js-menu-chevron')) {
                e.target.classList.contains('js-menu-chevron') ?
                    this.toggleAccordion(e.target) :
                    this.toggleAccordion(e.target.closest('.js-menu-chevron'));
            }
        });
    }

    toggleAccordion(trigger) {
        const parentMenu = trigger.closest('li');
        const submenu = parentMenu.querySelector('.js-submenu');

        if (parentMenu.classList.contains('opened')) {
            parentMenu.classList.remove('opened');
            submenu.classList.remove('displayed');
            trigger.classList.remove('rotated-down');
        } else {
            parentMenu.classList.add('opened');
            submenu.classList.add('displayed');
            trigger.classList.add('rotated-down');
        }
    }
}
